import React from 'react'
import styles from './klaviyo-form.scss'
import Card from "react-bootstrap/Card";

class KlaviyoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card className="bg-light p-3 my-4 klaviyo-form-component">
        <div className="text-primary font-weight-bold font-22 mb-1">{this.props.title}</div>
        {this.props.description}
        <div className={`m-0 ${this.props.id}`}></div>
      </Card>
    )
  }
}

export default KlaviyoForm